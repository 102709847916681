import React, { FC, useState, useEffect, Fragment, lazy, Suspense, } from "react";
const Pagination = lazy(() => import("../common/Pagination/Pagination"));
import { FILTER_CONTENT } from "../../utils/Constants/FilterContent";
const PageLoader = lazy(() => import("../common/Loader/PageLoader"));
import { getAllProducts } from "../../services/woocommerceServices/Shop";
import { Crumb, Product } from "../../types";
const Breadcrumb = lazy(() => import("../common/Breadcrumb"));
const Card = lazy(() => import("./Card"));
import CatDet from '../../assets/images/cat-details.png';
import proImg from "../../assets/images/banner-pr.png";
import proImgBg from "../../assets/images/pro-img.png";
import proImgBgMob from "../../assets/images/pro-ban-mob.png";
import person from "../../images/category-images/shutterstock_585077581.png";
import CatDetMob from '../../assets/images/cat-details-mob.png';
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  LazyLoadComponent,
  LazyLoadImage,
} from "react-lazy-load-image-component";
import { getQueryString } from "../../utils/helper";
interface ProductListProps {
  pageContext: any;
  categoryId: string;
  categoryName: string;
}

interface Props {
  pageData: any;
  products: Product[]
}

const ShopList: FC<Props> = ({ pageData, products }) => {
  const ITEMS_PER_PAGE = Number(process.env.GATSBY_GRAPHQL_RECORD_PER_PAGE);

  const allData = pageData?.allPeptideData;
  const [crumbs, setCrumbs] = useState<Crumb[]>([]);
  const pageNumber = Number(getQueryString("page"));
  const [currentPage, setCurrentPage] = useState(pageNumber || 1);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const productList = products.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  const [records, setRecords] = useState<Product[]>(productList || []);
  const [page, setpage] = useState();
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState(FILTER_CONTENT[0].value);

  const totalPages = Math.ceil(products.length / ITEMS_PER_PAGE);

  useEffect(() => {
    setRecords(products.slice(startIndex, startIndex + ITEMS_PER_PAGE));
  }, [currentPage, products])

  useEffect(() => {
    setCrumbs([{ label: 'All Peptides', link: null }]);
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const productsData: any = await getAllProducts(
          currentPage,
          sort
        );
        setRecords(productsData.data);
        setLoading(false);
        setpage(productsData.page);
      } catch (error) {
        // console.error(error, "<<-- Error in getting product list");
      } finally {
      }
    };

    if (sort !== "menu_order") {
      fetchProducts();
    }
  }, [currentPage, sort]);

  const filterhandeler = (e: any) => {
    // console.log(e.target.value);
    setSort(e.target.value);
    setCurrentPage(1);
  };

  return (
    <div className="px-[15px] md:px-[27px] xl:px-[88px] lg:px-[50px]  md:pb-[50px] pb-[30px] contain min-h-[50vh]">
      {/* Category List Banner */}
      <div className="list-ban relative xl:min-h-[200px] lg:min-h-[96px] md:min-h-[96px] min-h-[240px] flex flex-col justify-center">
        <div
          className="md:w-[50%] w-[80%] lg:pl-[30px] pl-[18px] z-10 relative"
          dangerouslySetInnerHTML={{
            __html: allData?.headerBannerText,
          }}
        />
        <div className="top-0 lg:right-0 right-0 z-[5] xl:w-[77%] w-[100%] h-full absolute">
          <img
            src={
              allData?.headerBannerImageDesktop1000X452?.node?.sourceUrl ||
              CatDet
            }
            alt="Spartan Peptide"
            width={243}
            height={279}
            loading="eager"
            className="md:block hidden w-full h-full object-cover  relative object-right rounded-[21px] ml-auto"
          ></img>
          {/* mobile image */}
          <img
            src={
              allData?.headerBannerImageMobile444X360?.node?.sourceUrl ||
              CatDetMob
            }
            alt="Spartan Peptide"
            width={243}
            height={279}
            loading="eager"
            className=" md:hidden block  w-full h-full object-cover relative object-right rounded-[21px] ml-auto"
          ></img>
        </div>
      </div>
      {/* Category List Banner */}

      <div className="flex md:items-center justify-between mb-5 md:flex-row flex-col all-pep ">
        <h1 className="font-sofiaMedium font-medium md:min-h-0 min-h-[50px]  xl:text-[42px] md:text-[32px] text-[32px] text-black">
          All Peptides
        </h1>
        <label className="text-[0px]"> Default Sorting</label>
        <select
          id="sort-direction"
          onChange={(e) => filterhandeler(e)}
          className=" bg-bg-grey h-[45px] rounded-[12px] text-[16px]  px-3 font-sofiaRegular font-regular focus:outline-none border md:mt-0 mt-2 md:w-[250px] w-full pr-[40px] md:mb-0 mb-3"
        >
          <option value="menu_order">Default Sorting</option>
          <option value="date">Sort by latest</option>
          <option value="price">Sort by price: low to high</option>
          <option value="price-desc">Sort by price: high to low</option>
          <option value="title">Sort by title: A to Z</option>
          <option value="title-desc">Sort by title: Z to A</option>
        </select>
      </div>

      <div className="mt-[-20px] mb-[-20px]">
        <Breadcrumb crumbs={crumbs} />
      </div>

      {loading ? (
        <div className=" min-h-[60vh] h-[60vh] flex justify-center items-center">
          <PageLoader />
        </div>
      ) :
        <section className="text-gray-600 body-font">
          <div className=" py-24 mx-auto">
            <div className="flex flex-wrap md:-m-4 -m-3">
              {records?.length > 0 &&
                records?.map((record, idx) => {
                  if (idx === 3) {
                    return (
                      <Fragment key={idx}>
                        <div className="lg:w-1/4 sm:w-1/2 w-full md:p-3 p-2  md:rounded-[21px] rounded-[21px] cursor-pointer ">
                          <LazyLoadImage
                            src={
                              allData?.adv1Image390X543?.node?.sourceUrl ||
                              person
                            }
                            alt="person"
                            width={330}
                            height={460}
                            loading="eager"
                            className="  w-full h-full lg:h-[387px] md:h-[481px] cat-adv object-cover lg:rounded-[21px] rounded-[21px]"
                          />
                        </div>
                        <Card record={record} />
                      </Fragment>
                    );
                  }

                  if (idx === 7) {
                    return (
                      <Fragment key={idx}>
                        <div className="w-full my-4">
                          <div className="bg-primary rounded-[22px]  lg:py-2 lg:pl-4 cat-ban-txt gap-2 flex lg:flex-row flex-col justify-start items-center relative">
                            <div
                              className=" slg:w-1/3 lg:w-[25%] w-full lg:px-0 lg:pt-0 pt-5 px-4"
                              dangerouslySetInnerHTML={{
                                __html: allData?.advBannerText,
                              }}
                            />

                            <div
                              className="slg:w-1/4 lg:w-[25%]  w-full lg:px-0 lg:pt-0 pt-5 px-4"
                              dangerouslySetInnerHTML={{
                                __html: allData?.advBannerTextList,
                              }}
                            ></div>
                            <div className="slg:w-1/4 lg:w-[25%] w-full ">
                              <LazyLoadImage
                                src={
                                  allData?.bannerImage?.node?.sourceUrl ||
                                  proImg
                                }
                                // width={245}
                                // height={211}
                                loading="lazy"
                                className="lg:w-[220px] lg:h-[184px] h-[300px] lg:mx-[unset] mx-auto"
                              />
                            </div>
                            <div className="lg:top-0 lg:right-0  z-[5] xl:w-[50%] lg:w-[50%] w-[100%] h-full lg:absolute relative  ">
                              <LazyLoadImage
                                src={
                                  allData?.advBannerImageDesktop430X226?.node
                                    ?.sourceUrl || proImgBg
                                }
                                // width={245}
                                // height={300}
                                className="w-full h-full lg:block hidden  object-contain  relative object-right rounded-[21px] ml-auto"
                                loading="lazy"
                              />
                              <LazyLoadImage
                                src={
                                  allData?.advBannerImageMobile772X562?.node
                                    ?.sourceUrl || proImgBgMob
                                }
                                width={245}
                                height={300}
                                loading="lazy"
                                className="w-[100%]  h-full object-contain lg:hidden block rounded-b-[21px] object-bottom"
                              />
                            </div>
                          </div>
                          {/* <img src={banner} alt="banner"  /> */}
                        </div>

                        <div className="lg:w-1/4 sm:w-1/2 w-full md:p-3 p-2  md:rounded-[21px] rounded-[21px] cursor-pointer ">
                          <LazyLoadImage
                            src={
                              allData?.adv2Image390X543?.node?.sourceUrl ||
                              person
                            }
                            alt="person"
                            loading="eager"
                            className="  w-full h-full lg:h-[387px] md:h-[481px] cat-adv object-cover lg:rounded-[21px] rounded-[21px]"
                          />
                        </div>

                        <Card record={record} />
                      </Fragment>
                    );
                  }

                  return (
                    <Fragment key={idx}>
                      <Card record={record} />
                    </Fragment>
                  );
                })}
            </div>
          </div>
        </section>
      }

      {records?.length > 0 && (
        <div className="pb-4">
          <Pagination
            page={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </div>
  );
};

export default ShopList;
